<template>
  <div class="h-full">
    <FinalForm
      ref="form"
      class="h-full"
      :submit="handleSubmit"
      :initialValues="initialValues"
      @change.self="handleFormChange"
    >
      <template v-slot="props">
        <form class="h-full" @submit="props.handleSubmit">
          <Wizard ref="steps" @cancel="close">
            <WizardPage title="1. where is the problem?">
              <template v-if="hasSreqPermission('SRCoBR')">
              <div class="form-row">
                <RadioGroupInput
                    name="submitterType"
                    label="are you submitting this request on behalf of a resident?"
                    :options="submitterTypes"
                />
              </div>
              <div class="form-row" v-if="formValues.submitterType === SUBMITTER_RESIDENT">
                <SelectInput
                    name="residentProfile"
                    label="what is the resident's name?*"
                    :options="residentProfiles"
                    :validate="required"
                />
                <div class="form-col"/>
              </div>
            </template>
              <div class="form-row">
                <RadioGroupInput
                  name="areaType"
                  label="is a problem in a unit?"
                  :options="areaTypes"
                />
              </div>
              <div class="grid grid-cols-2">
                <SelectInput
                  v-for="(id, index) in formValues.locationPath.length + 1"
                  :key="index"
                  :class="{hidden: locationDropdownOptions(formValues.locationPath, formValues.areaType, index).length === 0}"
                  :name="`locationPath.${index}`"
                  :label="locationDropdownLabel(formValues.areaType, index)"
                  :options="locationDropdownOptions(formValues.locationPath, formValues.areaType, index)"
                  :validate="locationValidation(formValues.areaType, index)"
                />
                <TextField
                  v-if="isCustomLocation(formValues.locationPath)"
                  name="customLocation"
                  label="other location*"
                  :validate="required"
                />
              </div>
              <div class="form-row mt-4" v-if="formValues.submitterType === SUBMITTER_RESIDENT && formValues.areaType === AREA_UNIT">
                <CheckboxInput
                  name="enterPermissionGranted"
                  value="enter permission"
                />
              </div>
            </WizardPage>

            <WizardPage title="2. what is the problem?">
              <div class="grid grid-cols-2 mb-8">
                <div>
                  <TextField
                    name="title"
                    label="title*"
                    placeholder="problem essence e.g. leaking faucet"
                    :validate="composeValidators(required, minLength(2), maxLength(100))"
                  />
                  <AttachmentsField
                    class="mt-8"
                    with-images
                    service="SREQ"
                    :resource="'images'"
                    :max-items="20"
                    :accept="[
                      'image/jpeg',
                      'image/png',
                    ]"
                  />
                </div>
                <TextField
                  class="flex flex-col"
                  inputClass="flex-grow"
                  name="description"
                  label="description*"
                  rows="5"
                  placeholder="enter details here"
                  :validate="composeValidators(required, minLength(10), maxLength(1000))"
                  multiline
                />
              </div>
              <div class="grid grid-cols-4 mb-8">
                <SelectInput name="priority" label="priority*" :options="priorities" :validate="required"/>
                <SelectInput
                  v-for="(id, index) in formValues.categoryPath.length + 1"
                  :key="index"
                  :class="categorySelectClasses(formValues.categoryPath, true, index)"
                  :name="`categoryPath.${index}`"
                  :label="categoryDropdownLabel(index)"
                  :options="categoryDropdownOptions(formValues.categoryPath, index)"
                  :validate="categoryValidation(index, triggerValidator('modificationRules.category', 'REQUIRED', required))"
                />
                <TextField
                  v-if="isCustomCategory(formValues.categoryPath)"
                  name="customCategory"
                  :label="categoryOtherLabel(formValues.categoryPath)"
                  :validate="composeValidators(required, minLength(2), maxLength(40))"
                />
              </div>
              <div v-if="formValues.areaType === AREA_UNIT" class="grid grid-cols-4 mb-8">
                <TextField name="alarmCode" label="alarm code" :validate="maxLength(50)"/>
              </div>
            </WizardPage>

            <WizardPage title="3. problem details & preferences">
              <div class="form-row" :class="{'w-1/3': statusSchema !== 'ADVANCED'}">
                <SelectInput
                  name="status"
                  label="status*"
                  :options="statuses"
                  :validate="required"
                />
                <template v-if="statusSchema === 'ADVANCED'">
                  <SelectInput
                      name="assigned"
                      label="assigned to"
                      :options="assignees"
                      :disabled="isDisabled(formValues,'assigned')"
                      :validate="triggerValidator('modificationRules.assignee', 'REQUIRED', required)"
                  />
                  <TextField
                      name="scheduled"
                      label="scheduled"
                      :disabled="isDisabled(formValues,'scheduled')"
                      :validate="composeValidators(
                        triggerValidator(
                          'modificationRules.schedule',
                          'REQUIRED',
                          composeValidators(minLength(2), maxLength(50), required)
                        ),
                        triggerValidator(
                          'modificationRules.schedule',
                          'OPTIONAL',
                          composeValidators(minLength(2), maxLength(50))
                        ),
                      )"
                  />
                </template>
              </div>
              <template v-if="formValues.submitterType === SUBMITTER_RESIDENT">
                <div class="row">
                  <p class="font-600 text-black mb-4">preferred contact method:</p>
                </div>
                <div class="form-row items-center w-1/2">
                  <RadioInput
                    class="contact-channel"
                    name="contactPreferences.preferredChannel"
                    label="email"
                    value="EMAIL"
                  />
                  <TextField
                    class="form-col-3 m-0"
                    name="contactPreferences.email"
                    label=""
                    :disabled="formValues.contactPreferences.preferredChannel !== 'EMAIL'"
                    :validate="triggerValidator('contactPreferences.preferredChannel', 'EMAIL', composeValidators(required, email))"
                  />
                </div>
                <div class="form-row items-center w-1/2">
                  <RadioInput
                    class="contact-channel"
                    name="contactPreferences.preferredChannel"
                    label="text"
                    value="PHONE"
                  />
                  <PhoneInput
                    class="form-col-3 m-0"
                    name="contactPreferences.phone"
                    label=""
                    :disabled="formValues.contactPreferences.preferredChannel !== 'PHONE'"
                    :validate="triggerValidator('contactPreferences.preferredChannel', 'PHONE', composeValidators(required, phoneNumber))"
                  />
                </div>
                <div class="row">
                  <p class="font-600 text-black mb-4">additional notification method</p>
                  <p class="mb-3 text-sm">
                    Resident will see all the notifications in the Resident portal.<br>
                    Additionally we can send the notifications to:
                  </p>
                </div>
                <div class="form-row items-center w-1/2">
                  <CheckboxInput
                    class="contact-channel"
                    name="notificationPreferences.sendEmail"
                    value="email"
                  />
                  <TextField
                    class="form-col-3 m-0"
                    name="notificationPreferences.email"
                    label=""
                    :disabled="!formValues.notificationPreferences.sendEmail"
                    :validate="triggerValidator('notificationPreferences.sendEmail', true, composeValidators(required, email))"
                  />
                </div>
                <div class="form-row items-center w-1/2">
                  <CheckboxInput
                    class="contact-channel"
                    name="notificationPreferences.sendText"
                    value="text"
                  />
                  <PhoneInput
                    class="form-col-3 m-0"
                    name="notificationPreferences.phone"
                    label=""
                    :disabled="!formValues.notificationPreferences.sendText"
                    :validate="triggerValidator('notificationPreferences.sendText', true, composeValidators(required, phoneNumber))"
                  />
                </div>
                <div class="form-row w-1/2">
                  <div class="form-col">
                    <p class="font-600 text-black mb-4">resident will be notified of:</p>
                    <CheckboxInput class="m-0" name="notificationPreferences.sendOnStatusChange" value="status change"/>
                    <CheckboxInput class="m-0" name="notificationPreferences.sendOnNewChatMessage" value="new chat message"/>
                    <CheckboxInput class="m-0" name="notificationPreferences.sendOnNewNote" value="new note"/>
                  </div>
                </div>
              </template>
            </WizardPage>
          </Wizard>
        </form>
      </template>
    </FinalForm>
    <loader :loading="loading" :backdrop="true" />
  </div>
</template>

<script>
  import {FinalForm} from 'vue-final-form';
  import {mapActions, mapGetters} from 'vuex';
  import NotifyMixin from '@/mixins/NotifyMixin';
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import InitializeFormMixin from '@/components/form/InitializeFormMixin';
  import ModalNavigation from "@/mixins/ModalNavigation";
  import TextField from '@/components/form/TextField';
  import SelectInput from '@/components/form/SelectInput';
  import RadioGroupInput from "@/components/form/RadioGroupInput";
  import RadioInput from "@/components/form/RadioInput";
  import CheckboxInput from "@/components/form/CheckboxInput";
  import PhoneInput from "@/components/form/PhoneInput";
  import Loader from '@/components/ui/Loader'
  import Wizard from '@/components/ui/wizard/Wizard';
  import WizardPage from '@/components/ui/wizard/WizardPage';
  import {
    AREA_COMMON,
    AREA_UNIT,
    SUBMITTER_MANAGER,
    SUBMITTER_RESIDENT
  } from "./constants";
  import LocationMixin from "./LocationMixin";
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import CategoryMixin from "@/views/sreq/requests/CategoryMixin";
  import AttachmentMixin from '@/mixins/AttachmentMixin';
  import AttachmentsField from '@/components/ui/AttachmentsField';
  import SreqMixin from "@/mixins/SreqMixin";
  import ModalFooterMixin from "@/components/ui/modals/ModalFooterMixin";

  export default {
    name: 'RequestCreate',

    components: {
      AttachmentsField,
      RadioInput,
      CheckboxInput,
      RadioGroupInput,
      SelectInput,
      TextField,
      PhoneInput,
      FinalForm,
      Loader,
      Wizard,
      WizardPage,
    },

    mixins: [
      SreqMixin,
      NotifyMixin,
      ValidatorMixin,
      InitializeFormMixin,
      ModalNavigation,
      ModalFooterMixin,
      LocationMixin,
      CategoryMixin,
      ConfirmationMixin,
      AttachmentMixin,
    ],

    data() {
      return {
        initialValues: {
          locationPath: [],
          categoryPath: [],
          submitterType: SUBMITTER_RESIDENT,
          areaType: AREA_UNIT,
          alarmCode: null,
          status: 'NEW',
          contactPreferences: {
            email: '',
            phone: '',
            preferredChannel: 'EMAIL',
          },
          notificationPreferences: {
            sendEmail: false,
            sendText: false,
            sendOnStatusChange: true,
            sendOnNewChatMessage: true,
            sendOnNewNote: false,
          },
          enterPermissionGranted: null,
          modificationRules: {},
        },
        formValues: null,
        loading: false,
        priorities: [],
        statuses: [],
        residentProfiles: [],
        assignees: [],
        AREA_UNIT,
        SUBMITTER_RESIDENT,
        submitterTypes: [
          { key: SUBMITTER_RESIDENT, value: 'yes' },
          { key: SUBMITTER_MANAGER, value: 'no' },
        ],
        areaTypes: [
          { key: AREA_UNIT, value: 'yes' },
          { key: AREA_COMMON, value: 'no' },
        ],
        statusSchema: null,
      };
    },

    computed: {
      ...mapGetters({
        backModal: 'modals/getLastModal',
        getLastFullPageRouteName: 'full_pages/getLastFullPageRouteName',
        tmpBuffer: 'msgs/tmpBuffer',
      }),
    },

    created() {
      this.formValues = this.initialValues;
    },

    mounted() {
      this.loadReferences();

      const {form} = this.$refs;

      if (!this.hasSreqPermission('SRCoBR')) {
        form.finalForm.change('submitterType', SUBMITTER_MANAGER);
      }

      this.$watch(
        () => this.formValues.submitterType,
        () => {
          form.finalForm.change('residentProfile', null);
        }
      );

      this.$watch(
        () => this.formValues.residentProfile,
        async (id) => {
          // For LocationMixin
          this.residentProfileIdProxy = id;

          const residentProfile = this.residentProfiles.find(r => r.id === id);

          if (residentProfile) {
            const {resident} = residentProfile;
            form.finalForm.change('residentEmail', resident.email);
            form.finalForm.change('residentPhone', resident.phone);
            form.finalForm.change('contactPreferences', resident.defaultContactPreferences);
            form.finalForm.change('notificationPreferences', resident.defaultNotificationPreferences);
            form.finalForm.change('enterPermissionGranted', resident.enterPermissionGranted);

            if (this.formValues.areaType === AREA_COMMON) {
              this.loadLocations();
            } else {
              this.insertResidentLocations([residentProfile.location]);
            }
          } else {
            form.finalForm.change('residentEmail', null);
            form.finalForm.change('residentPhone', null);
            form.finalForm.change('contactPreferences', {});
            form.finalForm.change('notificationPreferences', {});
            form.finalForm.change('enterPermissionGranted', null);

            await this.loadLocations();
          }

          const locationPath = [];

          // eslint-disable-next-line no-constant-condition
          while (true) {
            const options = this.locationDropdownOptions(locationPath, this.formValues.areaType, locationPath.length);

            if (options.length === 1) {
              locationPath.push(options[0].key);
            } else {
              break;
            }
          }

          form.finalForm.change('locationPath', []);

          setTimeout(() => {
            form.finalForm.change('locationPath', locationPath);
          });
        }
      )

      this.$watch(
        () => this.formValues.areaType,
        (areaType) => {
          // For LocationMixin
          this.areaTypeProxy = areaType;

          this.loadLocations();
          form.finalForm.change('locationPath', []);

          if (areaType === AREA_COMMON) {
            form.finalForm.change('enterPermissionGranted', null);
          }
        }
      );

      this.$watch(
        () => this.formValues.locationPath,
        (locationPath, oldLocationPath) => {
          this.compareLocationPaths(locationPath, oldLocationPath, this.loadLocations, updatedLocationPath => {
            form.finalForm.change('locationPath', updatedLocationPath);
          });
        }
      );

      this.$watch(
        () => this.formValues.categoryPath,
        (categoryPath, oldCategoryPath) => {
          this.compareCategoryPaths(categoryPath, oldCategoryPath, this.loadCategories, updatedCategoryPath => {
            form.finalForm.change('categoryPath', updatedCategoryPath);
          });
        }
      );
    },

    methods: {
      ...mapActions({
        saveToTmpBuffer: 'msgs/saveToTmpBuffer',
        resetTmpBuffer: 'msgs/resetTmpBuffer',
        resetSreqTmpBuffer: 'sreq/resetTmpBuffer',
      }),

      handleFormChange(state) {
        this.formValues = state.values;
      },

      loadReferences() {
        const {form} = this.$refs;

        this.loading = true;
        Promise.all([
          this.loadCategoriesData(),
          this.$sreqDataProvider.getList('priorities').then(priorities => {
            this.priorities = priorities.map(this.mapItemToOption);
            form.finalForm.change('priority', priorities.find(p => p.level === 2)?.id);
          }),
          this.$sreqDataProvider.getList('residentProfiles').then(residentProfiles => {
            this.residentProfiles = residentProfiles.map(item => ({
              ...item,
              key: item.id,
              value: `${item.resident.firstName} ${item.resident.lastName}`.toLowerCase()
            }));
          }),
          this.$sreqDataProvider.getList('assignees').then(assignees => {
            this.assignees = assignees.map(item => ({...item, key: item.id, value: `${item.firstName} ${item.lastName}`.toLowerCase()}));
          }),
          this.$sreqDataProvider.getStatusSchema('serviceRequests').then(({statusSchema}) => {
            this.statusSchema = statusSchema;
          }),
        ])
          .then(() => {
            this.initializeStatusWatcher();
          })
          .catch(error => this.notifyError(error.message))
          .finally(() => {
            this.loading = false;
            this.loadLocations();
          });
      },

      loadLocations(parentId) {
        const {areaType, residentProfile} = this.formValues;

        this.loading = true;
        return this.loadLocationsData(areaType, parentId, residentProfile)
          .catch(error => this.notifyError(error.message))
          .finally(() => {
            this.loading = false;
          });
      },

      loadCategories(parentId) {
        this.loading = true;
        return this.loadCategoriesData(parentId)
          .catch(error => this.notifyError(error.message))
          .finally(() => {
            this.loading = false;
          });
      },

      loadStatuses(values) {
        return this.$sreqDataProvider.getStateRules('serviceRequests', {
          data: this.getRequestPayload(values),
        })
          .then(stateRules => {
            this.statuses = stateRules.map(item => ({
              key: item.state.id,
              value: item.state.name.toLowerCase(),
              modificationRules: {
                assignee: item.assignee,
                category: item.category,
                schedule: item.schedule,
              },
              confirmationData: item.confirmationData,
            }));

            this.setModificationRules();
          });
      },

      initializeStatusWatcher() {
        this.$watch(
          () => this.formValues.status,
          () => {
            this.setModificationRules();
          },
        );
      },

      setModificationRules() {
        const {form} = this.$refs;

        const status = this.statuses.find(item => item.key === this.formValues.status);
        const modificationRules = {
          ...this.formValues.modificationRules,
          ...status?.modificationRules,
        };

        form.finalForm.change('modificationRules', modificationRules);
        Object.keys(modificationRules).forEach(field => {
          if (modificationRules[field] === 'NULL_ONLY') {
            form.finalForm.change(field, null);
          }
        });
      },

      isDisabled(values, field) {
        const rule = values.modificationRules[field];
        return rule === 'NULL_ONLY' || rule === 'READ_ONLY';
      },

      mapItemToOption(item) {
        return {
          key: item.id,
          value: item.name.toLowerCase(),
        };
      },

      locationDropdownLabel(areaType, index) {
        if (areaType === AREA_UNIT) {
          switch (index) {
            case 0:
              return 'building number*';

            case 1:
              return 'unit number*';
          }
        }

        return `problem location (level ${index + 1})${index === 0 ? '*' : ''}`;
      },

      getRequestPayload(values) {
        return {
          data: {
            title: values.title,
            description: values.description,
            alarmCode: values.alarmCode,
            enterPermissionGranted: values.enterPermissionGranted,
            location: {
              id: this.getLastPredefinedLocation(values.locationPath ?? []),
            },
            customLocation: this.isCustomLocation(values.locationPath ?? []) ? values.customLocation : null,
            contactPreferences: values.submitterType === SUBMITTER_RESIDENT
              ? {
                preferredChannel: values.contactPreferences.preferredChannel,
                email: values.contactPreferences.preferredChannel === 'EMAIL' ? values.contactPreferences.email : null,
                phone: values.contactPreferences.preferredChannel === 'PHONE' ? values.contactPreferences.phone : null,
              }
              : null,
            notificationPreferences: values.submitterType === SUBMITTER_RESIDENT
              ? {
                ...values.notificationPreferences,
                email: values.notificationPreferences.sendEmail ? values.notificationPreferences.email : null,
                phone: values.notificationPreferences.sendText ? values.notificationPreferences.phone : null,
              }
              : null,
            attachments: this.attachments.map(attach => ({id: attach.id})),
          },
          metadata: {
            reporterResidentProfile: values.residentProfile && {
              id: values.residentProfile,
            },
            assignee: values.assigned && {
              id: values.assigned,
            },
            category: (() => {
              const id = this.getLastPredefinedCategory(values.categoryPath ?? []);
              return id && {id}
            })(),
            customCategory: this.isCustomCategory(values.categoryPath ?? []) ? values.customCategory : null,
            currentState: {
              id: values.status,
            },
            priority: {
              id: values.priority,
            },
            schedule: values.scheduled,
          },
        };
      },

      async handleSubmit(values) {
        if (this.loading) {
          return;
        }
        switch (this.$refs.steps.currentStep) {
          case 0:
            return this.$refs.steps.goNext();

          case 1:
            this.loading = true;
            return this.loadStatuses(values)
              .then(() => {
                this.$refs.steps.goNext();
              })
              .catch(error => this.notifyError(error.message))
              .finally(() => {
                this.loading = false;
              });

          case 2: {
            return this.submitRequestData(values);
          }
        }
      },

      handleConfirmSubmit(action) {
        this.submitRequestData(action.values);
      },

      async submitRequestData(values) {
        this.loading = true;

        try {
          await this.uploadFiles();

          await this.$sreqDataProvider.create('serviceRequests', {
            data: this.getRequestPayload(values),
          });

          this.resetSreqTmpBuffer();

          this.close();
        } catch (error) {
          this.notifyError(error.message);
        } finally {
          this.loading = false;
        }
      },
    },

    beforeRouteLeave(to, from, next) {
      if (to.name === 'atts.gallery') {
        this.saveToTmpBuffer({
          step: this.$refs.steps.currentStep,
          values: this.formValues,
          locations: this.locations,
          categories: this.categories,
        });
      }
      next();
    },

    beforeRouteEnter(to, from, next) {
      if (from.name === 'atts.gallery') {
        next(vm => {
          vm.locations = vm.tmpBuffer.locations;
          vm.categories = vm.tmpBuffer.categories;
          vm.formValues = vm.initialValues = vm.tmpBuffer.values;
          vm.$nextTick(() => {
            vm.$refs.steps.setStep(vm.tmpBuffer.step);
            vm.resetTmpBuffer();
          });
        });
      } else {
        next();
      }
    },
  };
</script>

<style>
  .grid {
    grid-gap: 1rem 2rem;
  }

  .grid .form-col {
    margin: 0;
  }

  .form-col.m-0 {
    margin: 0;
  }

  .form-col.contact-channel {
    flex: 0 0 5rem;
    margin-right: 0;
    margin-top: 0.5rem;
  }

  .calendar-position {
    bottom: 100%;
    left: 0;
  }

  .form-col.form-col-3 {
    flex: 3 3 0;
  }
</style>
